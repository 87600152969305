import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

interface UserContextType {
  user: User | null;
  isLoadingUser: boolean;
  selectedStyleId: string | null;
  setSelectedStyle: (styleId: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [selectedStyleId, setSelectedStyleId] = useState<string | null>(() => {
    // Cargar el estilo seleccionado desde localStorage
    return localStorage.getItem('selectedStyleId') || null;
  });

  const setSelectedStyle = (styleId: string) => {
    setSelectedStyleId(styleId);
    // Guardar el estilo seleccionado en localStorage
    localStorage.setItem('selectedStyleId', styleId);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoadingUser(false);
      // Aquí puedes agregar lógica para establecer selectedStyleId, si es necesario
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, isLoadingUser, selectedStyleId, setSelectedStyle }}>
      {children}
    </UserContext.Provider>
  );
};
