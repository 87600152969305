import React from "react";
import { styles } from "./stylesData";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";

const StylesPage = () => {
  const { setSelectedStyle } = useUserContext();
  const navigate = useNavigate();

  const handleStyleSelection = (styleId: string) => {
    setSelectedStyle(styleId);
    navigate("/admin");
  };

  return (
    <div>
      <h1>Selecciona un Estilo para tus Links</h1>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {styles.map((style) => (
          <div
            key={style.id}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              cursor: "pointer",
              textAlign: "center",
              width: "200px",
            }}
            onClick={() => handleStyleSelection(style.id)}
          >
            <h2>{style.name}</h2>
            <div
              style={{
                border: "1px solid black",
                borderRadius: "15px",
                padding: "20px",
                width: "120px",
                height: "240px",
                margin: "0 auto",
                position: "relative",
                background: style.background.backgroundImage || style.background.backgroundColor,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "black",
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "-10px",
                  left: "calc(50% - 10px)",
                }}
              ></div>
              {['Link 1', 'Link 2', 'Link 3'].map((text, index) => (
                <div
                  key={index}
                  style={{
                    ...style.linkStyle,
                    margin: "10px 0",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "inline-block",
                    background: style.background.backgroundColor || "transparent",
                    width: "100%",
                  }}
                >
                  <span>{text}</span>
                </div>
              ))}
            </div>
            <p>Hover Effect: {JSON.stringify(style.hoverEffect)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StylesPage;
