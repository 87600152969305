import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const CanvasContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const StyleSelectionPage: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;

    const layers = [
      // Definir capas aquí con imágenes y textos
      {
        src: 'https://picsum.photos/300', // Ejemplo de fondo
        zIndex: -3,
      },
      {
        text: 'Evento Elegante', // Ejemplo de texto
        zIndex: 3,
        color: '#fff',
        fontSize: '30px',
        position: { x: 50, y: 100 },
      },
      // Agrega más capas según sea necesario
    ];

    let isMoving = false;
    const pointer = { x: 0, y: 0 };
    const motion = { x: 0, y: 0 };

    const drawCanvas = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      layers.forEach((layer) => {
        const offset = getOffset(layer, pointer, motion);
        if (layer.src) {
          const image = new Image();
          image.src = layer.src;
          context.drawImage(image, offset.x, offset.y);
        } else if (layer.text) {
          context.font = `${layer.fontSize} Arial`;
          context.fillStyle = layer.color;
          context.fillText(layer.text, offset.x, offset.y);
        }
      });

      requestAnimationFrame(drawCanvas);
    };

    const getOffset = (layer: {
            src: string; // Ejemplo de fondo
            zIndex: number; text?: undefined; color?: undefined; fontSize?: undefined; position?: undefined;
        } | {
            text: string; // Ejemplo de texto
            zIndex: number; color: string; fontSize: string; position: { x: number; y: number; }; src?: undefined;
        }, pointer: { x: any; y: any; }, motion: { x: any; y: any; }) => {
      const touchMultiplier = 0.3;
      const motionMultiplier = 2;
      const touchOffsetX = pointer.x * layer.zIndex * touchMultiplier;
      const touchOffsetY = pointer.y * layer.zIndex * touchMultiplier;
      const motionOffsetX = motion.x * layer.zIndex * motionMultiplier;
      const motionOffsetY = motion.y * layer.zIndex * motionMultiplier;

      return {
        x: touchOffsetX + motionOffsetX,
        y: touchOffsetY + motionOffsetY,
      };
    };

    // Event listeners for motion and pointer movement

    canvas.addEventListener('mousedown', (e) => {
      isMoving = true;
      pointer.x = e.clientX;
      pointer.y = e.clientY;
    });

    window.addEventListener('mousemove', (e) => {
      if (isMoving) {
        pointer.x = e.clientX - pointer.x;
        pointer.y = e.clientY - pointer.y;
      }
    });

    window.addEventListener('mouseup', () => {
      isMoving = false;
    });

    requestAnimationFrame(drawCanvas);

    return () => {
      window.removeEventListener('mousemove', () => {});
      window.removeEventListener('mouseup', () => {});
    };
  }, []);

  return (
    <CanvasContainer>
      <Canvas ref={canvasRef} />
    </CanvasContainer>
  );
};

export default StyleSelectionPage;
