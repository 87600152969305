import React from "react";

import Main from "./Main";
import Header from "./components/Header/Header";
import "./App.css";
import ContextMenu from "./components/UI/ContextMenu/ContextMenu";
import { FirebaseApp } from "firebase/app";
import { I18nextProvider } from "react-i18next";
import i18n from "./libs/i18n/lang";

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        {/* <ContextMenu/> */}
        <Main />
      </I18nextProvider>
    </div>
  );
}

export default App;
