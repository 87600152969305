// stylesData.ts
export type LinkStyle = {
    id: string;
    name: string;
    linkStyle: React.CSSProperties;
    hoverEffect: React.CSSProperties;
    background: React.CSSProperties;
  };
  
  export const styles: LinkStyle[] = [
    {
      id: "style1",
      name: "Estilo 1",
      linkStyle: {
        color: "blue",
        fontWeight: "bold",
      },
      hoverEffect: {
        textDecoration: "underline",
      },
      background: {
        backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
      },
    },
    {
      id: "style2",
      name: "Estilo 2",
      linkStyle: {
        color: "green",
        fontStyle: "italic",
      },
      hoverEffect: {
        transform: "scale(1.1)",
      },
      background: {
        backgroundImage: "url('path_to_image.jpg')",
      },
    },
    {
      id: "style3",
      name: "Estilo 3",
      linkStyle: {
        color: "red",
        textDecoration: "none",
      },
      hoverEffect: {
        textDecoration: "line-through",
      },
      background: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      id: "style4",
      name: "Estilo 4",
      linkStyle: {
        color: "#ff9900",
        textTransform: "uppercase",
      },
      hoverEffect: {
        fontWeight: "bolder",
      },
      background: {
        backgroundColor: "#000000",
      },
    },
    {
      id: "style5",
      name: "Estilo 5",
      linkStyle: {
        color: "#ff6347",
        fontSize: "1.2em",
      },
      hoverEffect: {
        color: "#ff4500",
      },
      background: {
        backgroundImage: "radial-gradient(circle, #ffcc33, #ff9900)",
      },
    },
    {
      id: "style6",
      name: "Estilo 6",
      linkStyle: {
        color: "#1e90ff",
        fontStyle: "oblique",
      },
      hoverEffect: {
        opacity: 0.8,
      },
      background: {
        backgroundImage: "linear-gradient(to left, #1e90ff, #00bfff)",
      },
    },
    {
      id: "style7",
      name: "Estilo 7",
      linkStyle: {
        color: "#2e8b57",
        textDecoration: "underline",
      },
      hoverEffect: {
        color: "#3cb371",
      },
      background: {
        backgroundColor: "#f0f8ff",
      },
    },
    {
      id: "style8",
      name: "Estilo 8",
      linkStyle: {
        color: "#8a2be2",
        fontWeight: "lighter",
      },
      hoverEffect: {
        textShadow: "1px 1px 2px #000",
      },
      background: {
        backgroundImage: "linear-gradient(to bottom, #8a2be2, #4b0082)",
      },
    },
    {
      id: "style9",
      name: "Estilo 9",
      linkStyle: {
        color: "#ff4500",
        letterSpacing: "0.1em",
      },
      hoverEffect: {
        fontSize: "1.1em",
      },
      background: {
        backgroundColor: "#ffffff",
      },
    },
    {
      id: "style10",
      name: "Estilo 10",
      linkStyle: {
        color: "#000000",
        textTransform: "capitalize",
      },
      hoverEffect: {
        backgroundColor: "#dcdcdc",
      },
      background: {
        backgroundImage: "linear-gradient(to top, #333333, #666666)",
      },
    },
    // Puedes seguir añadiendo más estilos según necesites
  ];
  