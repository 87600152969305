// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOiFXj9jDdxeL3_YTqFPl1_JkD7Ms0OCA",
  authDomain: "psstme-edf0b.firebaseapp.com",
  projectId: "psstme-edf0b",
  storageBucket: "psstme-edf0b.appspot.com",
  messagingSenderId: "1033805768911",
  appId: "1:1033805768911:web:2bf1b284085db0330aafba",
  measurementId: "G-BP9YVT13PW"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth();

export { firebaseApp, analytics };
export default firebaseConfig;