import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from "../../assets/logo.svg";

// Define la interfaz para las propiedades del evento
interface EventDetails {
  eventName: string;
  eventDescription: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  dressCode: string;
  otherDetails: string;
  styles?: {
    font: string;
    bgColor: string;
    bgImage: string;
    buttonColor: string;
    buttonText: string;
    textStyles?: {
      titleSize: string;
      titleColor: string;
      descriptionSize: string;
      descriptionColor: string;
      position: {
        title: string;
        description: string;
        time: string;
        location: string;
      };
    };
  };
}

const EventDetailsPage: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const eventDetails = state as EventDetails;

  const {
    font = 'Arial',
    bgColor = '#fff',
    bgImage = '',
    buttonColor = '#007bff',
    buttonText = 'Confirmar',
    textStyles = {
      titleSize: '24px',
      titleColor: '#000',
      descriptionSize: '16px',
      descriptionColor: '#333',
      position: {
        title: 'center',
        description: 'center',
        time: 'left',
        location: 'left',
      },
    },
  } = eventDetails.styles || {};

  return (
    <Container bgColor={bgColor} bgImage={bgImage}>
      <Title font={font} size={textStyles.titleSize} color={textStyles.titleColor} position={textStyles.position.title}>
        {eventDetails.eventName}
      </Title>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.description}>
        <strong></strong> {eventDetails.eventDescription}
      </Detail>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.time}>
        <strong>Fecha:</strong> {eventDetails.eventDate}
      </Detail>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.time}>
        <strong>Hora:</strong> {eventDetails.eventTime}hs
      </Detail>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.location}>
        <strong>Ubicación:</strong> {eventDetails.eventLocation}
      </Detail>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.location}>
        <strong>Dress Code:</strong> {eventDetails.dressCode}
      </Detail>
      <Detail font={font} size={textStyles.descriptionSize} color={textStyles.descriptionColor} position={textStyles.position.location}>
        <strong>Otros Detalles:</strong> {eventDetails.otherDetails}
      </Detail>
      <Button color={buttonColor}>{buttonText}</Button>
      <AppLogo />
    </Container>
  );
};

export default EventDetailsPage;

// Estilos con styled-components
const Container = styled.div<{ bgColor?: string; bgImage?: string }>`
  padding: 20px;
  background-color: ${(props) => props.bgColor || '#fff'};
  background-image: ${(props) => (props.bgImage ? `url(${props.bgImage})` : 'none')};
  background-size: cover;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h1<{ font?: string, size?: string, color?: string, position?: string }>`
  text-align: ${(props) => props.position || 'center'};
  margin-bottom: 20px;
  font-family: ${(props) => props.font || 'Arial'}, sans-serif;
  font-size: ${(props) => props.size || '24px'};
  color: ${(props) => props.color || '#000'};
`;

const Detail = styled.p<{ font?: string, size?: string, color?: string, position?: string }>`
  font-size: ${(props) => props.size || '16px'};
  margin-bottom: 10px;
  font-family: ${(props) => props.font || 'Arial'}, sans-serif;
  color: ${(props) => props.color || '#333'};
  text-align: ${(props) => props.position || 'left'};
`;

const Button = styled.button<{ color?: string }>`
  padding: 10px;
  background-color: ${(props) => props.color || '#007bff'};
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.color ? 'darken(10%, ' + props.color + ')' : '#0056b3'};
  }
`;

const AppLogo = styled.footer`
  margin: 0 auto;
  margin-top: 20px;
  width: 100px;
  height: 24px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
`;
