import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import StylesPage from "./pages/Styles/StylesPage";
import EventDetailsPage from "./pages/MakeInvite/EventDetailsPage";
import StyleSelectionPage from "./pages/MakeInvite/StyleSelectionPage";

const MakeInvitePage = React.lazy(() => import("./pages/MakeInvite/MakeInvitePage"));
const Landing = React.lazy(() => import("./pages/Landing/Landing"));
const NotFound = React.lazy(() => import("./pages/Idk/Idk"));
const AdminPage = React.lazy(() => import("./pages/AdminPage/AdminPage"));
const WhispPage = React.lazy(() => import("./pages/Whisp/Whisp"));
const Auth = React.lazy(() => import("./components/Login/Login"));
const SetUsernamePage = React.lazy(() => import("./components/Login/SetUserName"));

const PrivateRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const userLoggedIn = localStorage.getItem("userLoggedIn");
  return userLoggedIn ? <>{element}</> : <Navigate to="/login" replace />;
};

export const RoutesHelper = {
  main: "/",
  course: "course/:id",
  idParam: "/design/:userId/:designId",
};

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/login" element={<Auth />} />

        
        <Route path="/invite" element={<PrivateRoute element={<MakeInvitePage/>} />} />
        <Route path="/style-selection" element={<StyleSelectionPage />} />
        <Route path="/event-details" element={<EventDetailsPage />} />

        
        <Route path="/admin" element={<PrivateRoute element={<AdminPage />} />} />
        


        <Route path="/styles" element={<PrivateRoute element={<StylesPage />} />} />
        <Route path="/:username" element={<WhispPage />} />
        <Route path={RoutesHelper.main} element={<Landing />} />
        <Route path="/set-username" element={<SetUsernamePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
